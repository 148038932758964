import React from 'react'
import Styles from './benefits.module.scss'

const Benefit = ({ classes, icon, title, description }) => {
  const styles = classes || Styles
  return (
    <div className={`${styles.benefits_item} col-lg-4`}>
      <span>{icon}</span>
      <h2 className={styles.benefits_item_title}>{title}</h2>
      <p className={styles.benefits_item_description}>{description}</p>
    </div>
  )
}
const Benefits = ({ classes, benefits, children }) => {
  const styles = classes || Styles
  return (
    <div className={styles.benefits}>
      <h2 className="h1 d-none d-lg-block text-center text-secondary font-weight-bold mb-5">Why Uthru?</h2>
      <div className="row">
        {benefits.map((benefit, index) => (
          <Benefit
            key={index}
            title={benefit.title}
            description={benefit.description}
            icon={benefit.icon}
            classes={styles}
          />
        ))}
      </div>
      {children}
    </div>
  )
}

export default Benefits
