import React from 'react'
import Styles from './howItWorks.module.scss'

const Steps = ({ classes, index, description, title }) => {
  const styles = classes || Styles
  return (
    <li className={styles.list_steps_item}>
      <p className={styles.list_steps_headings}>
        <b>0{index}</b>
        <br className={styles.list_steps_linebreak} />
        <span className={styles.slash}>/</span> <b>{title}</b>
      </p>
      <p className={styles.list_steps_contents}>{description}</p>
    </li>
  )
}

const HowItWork = ({ steps, title, classes, children }) => {
  const styles = classes || Styles

  return (
    <section className={`${styles.howWorks} bg-white`}>
      <div className={styles.howWorks_layout}>
        <div className="about">
          <hr className={styles.about_special} />
          <h2 className={styles.about_title}>{title}</h2>
        </div>
        <div>{children}</div>
      </div>
      <ul className={`list-unstyled ${styles.list_steps}`}>
        {steps.map((step, index) => (
          <Steps index={index + 1} title={step.title} description={step.description} key={index} classes={styles} />
        ))}
      </ul>
    </section>
  )
}

export default HowItWork
